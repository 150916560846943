import defaultPath from '~/assets/images/default_student.png'
import { makeFilePath } from './makeFilePath'

export function getDefaultAvatarPath (path: string): string {
  const oldDefaultAvatarPath = '../img/logo2.png'
  let returnPath = defaultPath
  if (path != oldDefaultAvatarPath) {
    const newPath = makeFilePath(path)
    if (newPath != '') {
      returnPath = newPath
    }
  }
  return returnPath
}
