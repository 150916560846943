import { FreeTrialStatus } from '~/enum/freeTrialStatus'
import { MemberAdmissionStatus } from '~/enum/memberAdmissionStatus'

// 英語体験ステータスと入会区分から会員の入会ステータスを判定
export function memberAdmissionStatusCheck (freeTrialStatus: FreeTrialStatus, admissionDiv: string): MemberAdmissionStatus {
  let memberStatus = MemberAdmissionStatus.ADMISSION_ONLY
  const englishTrialFlg: boolean = freeTrialStatus === FreeTrialStatus.TRIALING
  const admissionFlg = admissionDiv === '1' || admissionDiv === '3'
  if (englishTrialFlg && admissionFlg) {
    memberStatus = MemberAdmissionStatus.ADMISSION_AND_TRIAL
  } else if (!englishTrialFlg && admissionFlg) {
    memberStatus = MemberAdmissionStatus.ADMISSION_ONLY
  } else if (englishTrialFlg && !admissionFlg) {
    memberStatus = MemberAdmissionStatus.TRIAL_ONLY
  } else {
    memberStatus = MemberAdmissionStatus.NOT_MEMBER
  }
  return memberStatus
}
