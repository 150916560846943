export enum MemberAdmissionStatus {
  // 入会のみ
  ADMISSION_ONLY = 'ADMISSION_ONLY',
  // 英語体験のみ
  TRIAL_ONLY = 'TRIAL_ONLY',
  // 入会+体験
  ADMISSION_AND_TRIAL = 'ADMISSION_AND_TRIAL',
  // 非会員
  NOT_MEMBER = 'NOT_MEMBER',
}
